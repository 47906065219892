/* Charity Worrk Banner */


/*

===================================
Typography
===================================
 */
 .charity-section__heading{
     font-size: 2rem;
     font-weight: 700;
 }

 .charity-section__desc{
     font-size: 1.2rem;
 }

 .charity-btn{
     font-size: 1.4rem;
     font-weight: 600;
     text-decoration: none;
     color: var(--primary-color);
     border-bottom: 2px solid var(--primary-accent);
 }

 .charity-btn:hover{
     opacity: .8;
 }


  /*
  ===================================
  Layout
  ===================================
   */

.charityImg,
.charity-right-col{
   grid-column: 2 / -2;
}

 @media (min-width: 800px) {

     .charityImg{
         grid-column: 2 / 3;
     }

     .charity-right-col {
         padding: 2em;
         grid-column: 3 / 5;
     }
 }



  /*
  ===================================
  General Styling
  ===================================
  */

.charity-section__desc{
  margin-bottom: 2em;
  margin-left: 1.5em;
  position: relative;
}

.charity-section__desc::before{
  content: "";
  position: absolute;
  width: 8px;
  height: 100%;
  background-color: var(--primary-accent);
  left: -1.5em;
}

.charityImg{
    object-fit: cover;
    width: 500px;
    height: 500px;
}
