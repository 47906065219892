/* Projects Stylesheet */
/*

===================================
Typography
===================================
 */

 .project-title {
 	font-size: 1.5rem;
 	margin: 0 0 10px;
 }

 .project-desc {
 	font-size: 1rem;
 	line-height: 1.5;
 	margin: 0 0 20px;
 }

 .project-btn {
 	font-size: .8rem;
 	font-weight: bold;
 	text-transform: uppercase;
 	text-decoration: none;
 }

 .icon{
 	font-size: 1.2rem;
 }

 .skill-label{
 	font-size: .65em;
 	font-weight: bold;
 	text-transform: uppercase;
}


 /*
 ===================================
 Layout
 ===================================
  */

.project-card {
	grid-column: 2 / -2;
    width: 100%;
	max-width: 800px;
    min-width: 320px;
	display: flex;
    flex-direction: column;
}


.cta-wrapper{
	display: flex;
	gap: 1em;
}

.project-img-cont {
	display: grid;
	justify-content: end;
	align-content: end;
    min-width: 400px;
    min-height: 200px;
    order: -1;


}

@media (min-width: 700px) {
    .project-card {
        flex-direction: row;
    }
    .project-img-cont{
        order: 1
    }
}

/*
===================================
General Styling
===================================
*/


.portfolio{
	/* padding-bottom: 4em; */
}
.project-card {
	background-color: #fff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	overflow: hidden;
	margin: 1em auto;
}

.project-img-cont {
	background-position: 0 20%;
	background-size: 150%;
	background-repeat: no-repeat;
}

.skills-wrapper{
	padding: 0 2em 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.project-details {
	padding: 2em;
}

@media (min-width: 700px){
    .project-details{
        flex-grow: 1;
    }
}

.project-btn {
	display: flex;
	align-items: center;
	gap: 10px;
    padding: .625em 1.3em;
	border-radius: .3em;
	color: #000000;
	transition: background-color 0.3s ease;
}

.btn-solid{
	background-color: rgba(51, 51, 51, 1);
	color: #fff;
    transition: all .3s ease-in-out;
}

.btn-solid:hover,
.btn-solid:focus {
	background-color: rgba(51, 51, 51, .8);
	text-decoration: none;
}

.btn-outline{
	border: 3px solid #333;
    transition: all .3s ease-in-out;
}

.btn-outline:hover,
.btn-outline:focus {
	background-color: rgba(51, 51, 51, .8);
	color: #fff;
}

.skill-label{
	background-color: #b5b5b5;
	color: #1e1e1e;
	padding: .4em .8em;
	border-radius: .3em;
    margin: .3em;
}

.modal-dialog{
    --bdr-radius: .6em;
    border-radius: var(--bdr-radius)
}

dialog{
    border: none;
    background-color: rgba(255,255,255, .6);
    max-width: 90vw;
    box-shadow: 0 0 1em rgb(0 0 0 / .6);
}

@media (min-width: 700px){
    dialog{
        max-width: 70vw;
        max-height: 95vh;
    }
}



dialog::backdrop{
    background-color: rgba(0,0,0,.6);
}

.modal{
    position: relative;
    padding: 1.5em;
    display: flex;
    justify-content: center;
}

.btn-modal{
    all: unset;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    position: absolute;
    top: 5px;
    right: 5px;
    transition: color .3s ease-in-out;
}

.btn-modal:hover,
.btn-modal:focus{
    color: var(--primary-accent);
}

.design-image{
    
}
