
/*
===================================
Typography
===================================
 */

 .services__subtitle{
     font-size: 1.85rem;
 }

 .service__icon{
     font-size: 3rem;
     color: var(--primary-color);
 }


 /*
 ===================================
 Layout
 ===================================
  */


.services{
	width: 100%;
	/* text-align: center; */
	/* padding-bottom: 4em; */

}

.left-col{
 margin-bottom: 2em;
}


.right-col{
	/* width: 50%; */
    margin-top: 2em;
}

.section-title{
	grid-column: 2 / -2;

}

.left-col,
.right-col{
	grid-column: 2 / -2;
	display: flex;
	flex-direction: column;
}


@media (min-width: 1000px) {
	.left-col{
		grid-column: 2 / 3;
	}

	.right-col{
		grid-column: 3 / 5;
	}


}

.btn-services{
	/* grid-column: 2 / -2; */
	margin-right: auto;
    background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
    background-size: 200% auto;
    color: #333;
    transition: 0.3s ease;
}

.btn-services:hover,
.btn-services:focus{
    background-position: right center;
}

.services-desc{
    font-size: 1.1rem;
  /* letter-spacing: .1em; */
}
.services-grid{
	margin-bottom: 3em;
}

@media (min-width: 800px) {

	.services{
		text-align: unset;
	}
	.services-grid{
		display: grid;
		grid-template-columns: repeat(2, 1fr 1fr);
		/* grid-template-rows: repeat(2, 1fr); */
	}

	.service{
		grid-column: span 2;
	}

    .right-col{
        margin-top: 0;
    }
}

.service{
	margin: 0 1em;
}



  /*
  ===================================
  General Styling
  ===================================
   */


.services,
.portfolio{
	background-color: var(--background-ltgrey);
}

.services__subtitle{
	position: relative;
	margin-top: 0;
}
.services__subtitle::after{
	content: "";
	position: absolute;
	background-color: var(--primary-accent);
	width: 70px;
	height: 5px;
    left: 0;
	bottom: 0;
}

.services-desc{
   margin-top: 0;
   margin-bottom: 2em;
}

.service{
    margin-bottom: 2em;
}

.service__icon{
    position: relative;
    z-index: 1
}

.service__icon::after{
   content: "";
   position: absolute;
   width: 50px;
   height: 50px;
   border-radius: .2em;
   background-color: var(--primary-accent);
   opacity: .5;
   top: -20px;
   right: 20px;
   z-index: -1;
}

@media (min-width: 800px){
    .service::after{
        /* top: 0;
        left: 50px; */
    }
}
