/* Hero Section Stylesheet */


/*
===================================
Typography
===================================
 */

 .hero-title {
 	font-size: clamp(3rem, 2.5vw, 7rem); /*4rem*/
 	margin: 0;
 }

.hero-subtitle{
    font-weight: 600;
    letter-spacing: .15em;
    margin-bottom: 0;
    display: none;
}

 .hero-desc {
 	font-size: 1.2rem;
 }


/*
===================================
Layout
===================================
*/

.hero > * {
    grid-column: 2 / -2;
}

.hero__content {
	display: flex;
	flex-direction: column;
	order: 1;

}

.hero-title {
   margin: 0;
}

.hero-subtitle{
	order: -1;
}

.hero-desc {
   width: 65%;
   margin: 1.2em 0 2em 0;
}

.btn-wrapper{
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: 1em;
}
@media (min-width: 800px) {

    .hero-title{
        width: 50%;

    }

	.hero-desc{
		width: 55%;
	}

    .btn-wrapper{
		flex-direction: row;
		align-self: unset;
	}
}


/*
===================================
General Styling
===================================
*/

.hero {
	background-image: url('./img/hero-3.jpg');
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
    margin: 4em 0;
    position: relative;
}

@media (min-width: 1250px) {
	.hero{
		background-size: contain;
	}
}

.hero::before,
.hero::after{
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    border-radius: 1em;
    rotate: 15deg;
    background-color: var(--primary-color);
    opacity: .2;
}

.hero::before{
    left: -30px;
    top: -50px;
}

.hero::after{
    top: 120px;
    left: 60px;
}

.hero__right{
	margin: 3em;
	border: 1px solid blue;
}

.hero__image{
	object-fit: cover;
	object-position: center;
}

.cta-button {
	background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
	background-size: 200% auto;
	padding: 1em 2.5em;
	border-radius: .3em;
    color: #333;
	transition: 0.3s ease;

}

.cta-button:hover {
	background-position: right center;
}
