/* index.css stylesheet */

/*
-----------------------------
Global Styling
-----------------------------
*/

:root{
	--primary-color: #385752;
	--primary-accent: #DD9656;
	--secondary-accent: #4B2E32;
	--background-ltgrey: #F3F3F3;

}

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Nunito', sans-serif;
	font-size: 1rem;
	line-height: 1.6;
	color: #333;
	margin: 0;
	padding: 0;
	min-width: 400px;
	overflow-x: hidden;
}

@media (min-width: 800px) {

	html{
		/* font-size: 2rem; */
	}
	body {
		/* font-size: 22px; */
	}
}
img {
	max-width: 100%;
}

*{
    box-sizing: border-box;
}


/*
-----------------------------
Button Styling
-----------------------------
*/

.btn{
	text-decoration: none;
	padding: 1em 2.5em;
	border-radius: .3em;
	position: relative;
	text-transform: capitalize;
	font-weight: 600;
}

.btn:before{
	content: "";
	position: absolute;
	top: 8px;
	right: -8px;
	left: 8px;
	bottom: -8px;
	z-index: -1;
	background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}

.btn:is(:hover, :focus){
	background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}



/*=================================
TYPOPGRAPHY
===================================
 */

.section-title{
	font-size: 2.2rem;
	text-align: center;
	margin-top: 0;
	margin-bottom: 2em;
}

/*=================================
GENERAL LAYOUT
===================================
 */

.main-grid {
    display: grid;
    grid-template-columns: minmax(1em, 1fr) minmax(0px, 500px) minmax(1em, 1fr);

}

@media (min-width: 600px) {
    .main-grid {
        grid-template-columns: minmax(1em, 1fr) repeat(3, minmax(150px, 380px)) minmax(1em, 1fr);
		grid-column-gap: 2em;
    }
}

section{
	padding: 6em 0;

}
