/* CTA Stylesheet */


/*
===================================
Typography
===================================
 */

 .cta-banner__title{
 	font-size: 2.3rem;
 	color: #fff;
 }

 .cta-banner__subtitle{
    font-size: 1.2rem;
    font-weight: 700;
 	text-transform: uppercase;
 	color: var(--primary-accent); /*rgba(255, 229, 82, .8)*/
 	letter-spacing: 2px;
 }

 .cta-banner__btn{
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
 }


 /*
 ===================================
 Layout
 ===================================
  */

  .cta-banner__wrapper{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }

  .cta-banner__title{
  	width: 80%;
  	text-align: center;
  	margin-top: 0;
  	margin-bottom: 1em;
  }

  .cta-banner__subtitle{
  	order: -1;
  	margin-bottom: 0;
  }




  /*
  ===================================
  General Styling
  ===================================
   */

.cta-banner{
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6))
	,url('./img/computer.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 9em 0;
}

.cta-banner__btn{
	border: 2px solid #fff;
	border-radius: .2em;
	padding: .8em 2em;
	transition: all 350ms ease-in-out;
	text-decoration: none;
}

.cta-banner__btn:is(:hover, :focus){
    background-color: var(--primary-accent);


}
