/* Nav Styling
/*


===================================
Typography
===================================
 */

.logo {
    color: white;
    font-weight: bold;
}

.nav-list li a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    display: block;
    padding: .5em 2em;
}

.active a {
   color: #008AF8;
   font-weight: bold;
}

.nav-list li a {
   font-weight: bold;
   margin-bottom: 2px;
   transition: .3s ease-in-out;
}

.nav-list li a:is(:hover, :focus) {
   /* background: #2E363D; */
   color: var(--primary-accent);
}


/*
===================================
Layout
===================================
*/

.logo{
    display: flex;
    align-items: center;
}


/*
===================================
General Styling
===================================
*/


.header {
	background: var(--primary-color);
	padding: 1em;
	display: flex;
	justify-content: space-between;
    align-items: center;
	overflow: hidden;
}


.nav {
   position: fixed;
   top: 0;
   right: 0;
   height: 100%;
   background: #1D2226;
   width: 66%;
   visibility: hidden;
   transform: translateX(50%);
   opacity: 0;
   transition: all .3s;
   z-index: 100;
}

.exit {
   width: 2em;
   float: right;
   margin: .5em;
   cursor: pointer;
}

.nav-list {
   list-style-type: none;
   padding: 0;
   margin-top: 4em;
}

.open {
   cursor: pointer;
   margin: .5em;
}

.open-nav {
   visibility: unset;
   opacity: 1;
   transform: translateX(0);
}



@media (min-width: 700px) {

	.header {
		overflow: visible;
	}

	.open, .exit {
		display: none;
	}

	.nav {
		visibility: unset;
		background: none;
		position: unset;
		height: auto;
		opacity: 1;
		transform: translateX(0);
	}

	.nav-list {
		display: flex;
		margin-top: auto;
		width: auto;
		float: right;
		margin: 0;
	}

	.nav-link a {
		color: rgba(255,255,255,.1);
		display: block;
		padding: 1em;
	}

	.active a {
		color: white;
	}

}
