/* Footer Stylesheet */


/*
===================================
Typography
===================================
 */


.footer-title{
    font-size: 1.8rem;
    color: rgba(255,255,255,1);
    margin: 0;
}

.footer-subtitle{
    display: none;
    font-size: .875rem;
    font-weight: 500;
    color: var(--primary-accent);
    text-transform: uppercase;
    letter-spacing: .125em;
    order: -1;
    margin-bottom: 0;
}

.footer-details{
    font-weight: 500;
    color: rgba(255,255,255,.8);
    letter-spacing: .08rem;
}

.footer-links li a,
.footer-socials a{
    text-decoration: none;
}

.footer-links li a{
    font-weight: 600;
    color: rgba(255,255,255,1);
    transition: color 200ms ease-in;
}

.footer-links li a:hover{
    color: var(--primary-accent);
}

.footer-copyr{
    color: rgba(255, 255, 255, 0.8);
}

.footer__icon{
	font-size: 1.5rem;
	color: var(--primary-accent);
    transition: 250ms ease-in-out;
}

.footer__icon:hover{
    color: rgba(0,0,0,.4);
}

.footer__icon i{
    margin-top: auto;
}

.contacts-cont-bot h5{
    font-size: 1rem;
    letter-spacing: .15em;
    color: #fff;
    margin: 0 0 1em 0;
}

.email-cont span,
.phone-cont span{
    font-size: .85rem;
    color: rgba(255, 255, 255, .8);
    margin-left: 1em;
}



 /*
 ===================================
 Layout
 ===================================
  */


.footer{
    padding: 2em;
}

.footer-desc{
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
}

.footer-details{
    width: 80%;
    margin-top: 2em;
}

.footer-contacts{
    grid-column: 1 / -1;
    justify-self: start;
}

.contacts-cont-top,
.contacts-cont-bot{
    margin-top: 2em;
}

.contacts-cont-bot{
    margin-bottom: 2em;
}

.footer-copyr{
    grid-column: 1 / -1;
}

.footer-links{
    display: flex;
    gap: 20px;
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    color: #000000;
}

.footer-socials{
    display: flex;
    gap: 20px;

}

.email-cont,
.phone-cont{
    display: flex;
    align-items: center;
}


@media (min-width: 800px) {
 .footer-desc{
     grid-column: 1 / 4;
 }

 .footer-contacts{
     grid-column: 4 / 6;
     /* display: flex; */
     gap: 2em;

 }

 .contacts-cont-top{
     margin-top: 0;
 }

}


  /*
  ===================================
  General Styling
  ===================================
  */

  .footer{
    background-color: var(--primary-color);
  }

  .phone-cont{
      display: none;
  }
