/* About Section Styles */

@property --gradient-angle {
	synyax: "<angle>";
	initial-value: 132deg;
	inherits: false;
}

:root {
	--color-1: #385752;
	--color-2: #273d3c;
	--color-3: #384057;
	--color-4: #385738;
	--color-5: #DD9656;
}



/*
===================================
Typography
===================================
 */

 .skills-title{
 	font-size: 1.5rem;
 	width: 50%;
 	position: relative;
 }

 .about-desc{
	 font-size: 1.2rem;
 }


 /*
 ===================================
 Layout
 ===================================
  */

  .about{
  	 padding-bottom: 6em;
  }
  .about-img-cont,
  .desc-wrapper{
   grid-column: 2 / -2;
  }

  .skills-list{
  	list-style: none;
  	display: flex;
  	flex-wrap: wrap;
  	gap: 10px;
  	margin: 2em 0;
  	padding: 0;
  	width: 85%;
  }


  @media (min-width: 800px) {

  	.about-img-cont{
  		grid-column: 2 / 3;
  	}

  	.desc-wrapper{
  		grid-column: 3 / 5;
		margin-left: 2em;
  	}

  	.about-desc{
  		margin-top: 0;
  	}


  }


  /*
  ===================================
  General Styling
  ===================================
   */


.about-img-cont{
	border: 5px solid;
	padding: 5px;
	border-image-source: linear-gradient(45deg, var(--primary-color), var(--primary-accent));
	border-image-slice: 1;
	box-shadow: 0px 19px 17px -3px rgba(0,0,0,0.1);
	position: relative;
}


.about-image{
	object-fit: cover;
	width: 100%;
 	height: 100%;
	rotate: -8deg;
	position: relative;
}

.about-image::after{
	content: "";
	position: absolute;
	width: 100px;
	height: 100px;
	background: rgba(255, 254, 0, 1);
	top: 0;
	right: 0;
	z-index: 10;
}

.about-desc{
	margin-top: 3em;
}

.skills-title::after{
	content: "";
	position: absolute;
	background-color: var(--primary-accent);
	width: 70px;
	height: 5px;
	left: 0;
	bottom: 0;
}

.skill{
	background-color: #333;
	color: rgba(255, 255, 255, 1);
	padding: .5em 1.5em;
	border-radius: .3em;
}

/* Dot styling square */
.hero-bg{
    position: absolute;
    width: 150px;
    height: 150px;
    background-image: radial-gradient(circle, #07AAF0 15%, transparent 10%), radial-gradient(circle, #07AAF0 15%, transparent 10%);
    background-size: 25px 25px;
    background-position: 0 0, 50px 50px;
    top: -60px;
    left: -30px;
    z-index: -1;
}
